@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

.content-container {
  max-width: 1536px; /* or any width you prefer */
  margin: 0 auto;
  padding: 0;
}

.navbar {
  transition: background-color 0.3s;
}

.navbar.active {
  background-color: rgba(0, 0, 0, 0.8);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
